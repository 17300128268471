* {
  box-sizing: border-box;
  min-width: 0px;
}
body {
  font-family: 'Arial Narrow', Arial, sans-serif;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

h4 {
  font-weight: bold;
  margin-top: 1em;
  margin-bottom: 1em;
}

P {
  line-height: 1.5em;
  margin-bottom: 1em;
}


li {
  line-height: 1.5em;
  margin-bottom: 0.5em;
}

ul {
  padding-inline-start: 20px;
}


.App {
  width: 100%; height: 100%; overflow: hidden;
}
#root { width: 100%; height: 100%; overflow: hidden;
}
.header {
  flex: 0 0 auto;
  background: #222;
  padding: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px;
  width: 100%;
  height: 60px;
}
.headerContent {
  padding: 0;
  width: 100%;
  height: 100%;
  max-width: 1200px;
  margin-left: auto; margin-right: auto;
  margin-top: 0; margin-bottom: 0;
}
.main {
  position: absolute;
  display: flex; 
  flex-direction: column;
  overflow: hidden;
  top: 0px;
  bottom: 0;
  left: 0px;
  right: 0px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  color: #222;
  background-color: #C0C0C0;
}
.content {
  flex: 1 1 auto;
  width: 100%;
  color: #222;
  overflow-y: auto;
  background-color: #E0E0E0;
}

.onecolumn {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.columns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.column1 {
  flex: 0.5 0.5 auto;
  max-width: 40%;
  margin: 0px;
  padding-left: 64px;
}
.column2 {
  flex: 0.5 0.5 auto;
  max-width: 40%;
  margin: 0px;
  padding-left: 64px;
  padding-right: 16px;
}

@media only screen and (max-width: 1000px)
{
  .column1 {  max-width: 80%;   }
  .column2 { max-width: 80%; }

}

.row:after {
  content: "";
  display: table;
  clear: both;
}
.banner {
  flex: 0 0 60px;
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  max-width: 100%;
}


.headerButton {
  background-color: #222;
  vertical-align: middle;
  padding:0;
  margin:0;
  border: 0;
  display: inline-block;
  cursor: pointer;
  color: #888;
  font-family: 'Arial Narrow', Arial, sans-serif;
  font-size: 9.5pt;
  min-width: 120px;
  padding-bottom: 4px;
  height: 100%;
  align-content: center;
}

.headerButton:focus {
  outline: 0;
  background-color: #000;
}                       

.headerButton:hover {
  color: #FFFFFF;
}

.headerButton:active {
  position: relative;
  top: 1px;
  left: 1px;
}

.imgButton {
  background-color: transparent;
  opacity: 0.8;
  vertical-align: middle;
  padding: 0;
  margin: 0;
  border: 0;
  display: inline-block;
  cursor: pointer;
  color: #888;
  font-family: 'Arial Narrow', Arial, sans-serif;
  font-size: 9.5pt;
  align-content: center;
}

.imgButton img {
  opacity: 0.5;
}
.imgButton:hover IMG {
      opacity: 1.0;
}
.imgButton:hover {
  background-color: transparent;
  opacity: 1.0;
}
.imgButton:focus {
  outline: 0;
  background-color: transparent;
}                       

.imgButton:active {
  position: relative;
  top: 1px;
  left: 1px;
}





.fine_print {
  font-size: 0.7em;
  padding-left: 48px;
  padding-right: 48px;
}

.faqLink {
  margin-left: 24px;
}

.contacts {
  margin-left: 24px;
}




.pageButton {
  box-shadow: inset 0px 2px 3px 0px #888;
  background: linear-gradient(to bottom, #cdcdcd 5%, #ededed 100%);
  background-color: #ededed;
  border-radius: 6px;
  border: 1px solid #cdcdcd;
  display: inline-block;
  cursor: pointer;
  color: #444;
  font-family: Arial;
  font-size: 10pt;
  font-weight: normal;
  padding: 6px 24px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #ffffff;
}

.pageButton:hover {
  background: linear-gradient(to bottom, #ededed 5%, #cdcdcd 100%);
  background-color: #cdcdcd;
}

.pageButton:active {
  position: relative;
  top: 1px;
}

      
.faqFrame {
  border: 1pt #888 solid;
  border-radius: 4px;
  padding-top: 16px;
  margin-bottom: 32px;
}

.faqHeader {
  padding-left: 16pt;
  padding-right: 16pt;

}
.faqContent {
  padding-left: 16pt;
  padding-right: 16pt;
  overflow-y: auto;
}

.faqFramePopup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  background: white;
  position: absolute;
  overflow: hidden;
  left: 16px;
  right: 16px;
  top: 8px;
  bottom: 8px;
  border: 1pt #888 solid;
  border-radius: 4px;
  padding-top: 16px;
  margin-bottom: 32px;
}